$default-border-radius: 8px;
$default-padding: 10px;

.select-additional-modal {
  overflow: auto;
  background: #fff;
  height: 550px;
  width: 90%;
  max-width: 450px;
  margin: 50px auto;
  padding: $default-padding;
  border-radius: $default-border-radius;
  padding-bottom: 40px;

  .save {
    width: 100%;
    height: 35px;
    margin-top: 15px;
  }

  .first-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .title {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .second-section {
    overflow-y: auto;
    max-height: 80%;

    .additionals {
      padding: 10px;
      border-radius: 8px;

      .additional {
        margin: 10px 0;
        border-radius: 8px;

        .sub-title--additional {
          background: #777;
          color: #fff;
          padding: 7px 7px 7px 15px;
          border-radius: 8px;
        }

        .additional-item {
          border-bottom: 2px solid #f2f2f2;
          text-align: center;
          padding: 10px 15px 10px 15px;
          display: flex;
          justify-content: space-between;

          .item-information {

            .item-information-title,
            .item-information-price {
              font-weight: bold;
            }

            .item-information-title {
              font-weight: bold;
            }

            .item-information-price {
              font-weight: bold;
              color: #008000;
            }
          }

          .item-action {
            .amount {
              i {
                font-size: 14px;
                margin: 5px;
                color: #008000;
              }

              span {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}